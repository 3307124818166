/** 
 * 万效云
 */
<template>
  <v-card class="EfficiencyCloud">
    <v-banner
        single-line
        height="400"
        class="banner"
      >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerEfficiencyCloud400.jpg"
          height="100%"
          :transition="false"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0 max-width-1200" style="margin-top: 130px;">
          <v-card color="transparent" flat>
            <v-card-title class="white--text pa-0 font-size-50 font-weight-bold font-size-banner-title">
              万效云
            </v-card-title>
            <v-card-title class="white--text pa-0 mt-7 font-size-banner-tip" style="line-height:30px">
              结合AR/VR沉浸式互动体验，构建广告新生态<br/>利用算法引擎实时稳定投放成本
            </v-card-title>
            <!-- <v-card-actions class="pa-0 mt-16">
              <v-btn
                  rounded
                  width="160"
                  height="50"
                  color="#ffffff"
                  class="font-size-20"
              >
                <span style="color: #226EFF;">立即咨询</span>
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-container>
      </v-img>
    </v-banner>  
    <!-- AR/VR沉浸式互动广告 -->
    <v-card class="con2 wrap" flat>
      <div class="public-title">
        <p>INTERACTIVE ADVERTISING</p>
        <div>
          <span>沉浸式互动广告</span>
          <span>开创互动广告全新形式</span>
        </div>
      </div>
      <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs pt-6">
        <v-tab v-for="(v, k) in data.second" :key="k" >
          <b>{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in data.second" :key="k" :transition="false">
            <div class="box">
              <transition name="slide-fade" appear>
                <div class="left" v-show="tab == k">
                  <v-card-text class="title" v-text="v.lunTitle" />
                  <v-card-text class="title" v-text="v.lunTitle1" />
                  <v-card-text class="text mt-4" v-text="v.tip" />
                  <div>
                    <div class="tab" v-cloak>
                      <span
                        v-for="(item,index) in v.btns"
                        :key="item.id"
                        :class='currentIndex==index?"active":""'
                        @click="change(index)">
                        {{item.button}}
                      </span>
                    </div>
                  </div>
                </div>
              </transition>
              <transition name="slide-fade-reverse" appear>
                <div v-show="tab == k" >
                  <div 
                      v-for="(m,index) in v.btns"
                      :key="index"
                      class="right"
                     v-show="currentIndex==index"
                   >
                    <v-img
                      class="iphone-item d-flex align-center" height="100%"
                      :src="m.imgUrl"
                    >
                      <img :class="m.id == 1? 'item-img-big' : ''"   :src="m.src1" alt="" v-show="m.id==1">
                      <img :class="m.id == 2? 'item-img-big2' : ''"  :src="m.src1" alt="" v-show="m.id==2">
                      <img :class="m.id == 3? 'item-img-big3' : ''"  :src="m.src1" alt="" v-show="m.id==3">
                      <img :class="m.id == 4? 'item-img-big4' : ''"  :src="m.src1" alt="" v-show="m.id==4">
                     </v-img>
                  </div>
                </div>
              </transition>
            </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- 我们的优势 -->
    <v-card class="con1 wrap" :flat="true">
      <div class="public-title" style="margin-top:20px">
        <p>PRODUCT ADVANTAGES</p>
        <div>
          <span>我们的优势</span>
          <!-- <span>近80万家APP媒体，日均曝光150亿次</span> -->
        </div>
      </div>
      <div class="con">
        <v-row no-gutters justify='space-between' class="pt-7">
          <v-col v-for="(v, k) in data.frist" :key="k" cols="12" sm="3" >
            <div class="box">
              <img :src='v.img' />
              <v-card-title  class="con_tit" style="padding-bottom: 16px;padding-top:25px"> {{ v.title }} </v-card-title>
              <v-card-text class="con_text "> {{ v.text }} </v-card-text>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 万效云合作渠道 -->
    <v-card flat class="con3">
      <div style="height:1px;margin-top:50px;"></div>
      <div class="public-title mb-5">
        <p>COOPERATE</p>
        <div>
          <span>整合主流渠道，媒体推广全方位</span>
          <span>聚集TOP媒体的优质流量，帮助广告主实现最大范围用户覆盖</span>
        </div>
      </div>
      <div class="box ">
        <v-img
          src="https://h5.ophyer.cn/official_website/other/WXYLOGO.png" contain
        />
      </div>
      <!-- <v-container fluid class="imgWarp mt-9">
          <v-row dense justify="center">
            <v-col
              v-for="item in imgUrls"
              :key="item.id"
              :cols="2"
            >
              <img
                  src="@/assets/images/about/22.jpg"
                  height="120px"
                  width="120px"
                />
            </v-col>
          </v-row>
      </v-container> -->
    </v-card>
  </v-card>
</template>

<script>

import contentData from '@/static/contentData.js'

export default {
  name: "EfficiencyCloud",
  data(){
    return{
      currentIndex: 0,
      data: contentData.EfficiencyCloud,
      model: 0,
      bannerLoad: false,
      imgLoad: false,
      tab:null,
    }
  },
  methods: {
    handlebtns(){
      console.log('2')
    },
    change(index){
      this.currentIndex = index;
    }
  }
}
</script>

<style lang="scss" scoped>
.item-img-big {
  width: 176px;
  height: 80px;
  position: absolute;
  top: 102px;
  right:59px;
  animation-delay: 3s;
  animation-fill-mode:forwards;
	// 关键帧名称
	-webkit-animation-name:scaleDraw;
	// 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
	// 动画播放的次数
  -webkit-animation-iteration-count:infinite;
	// 动画所花费的时间
  -webkit-animation-duration:4s;
}

.item-img-big2{
  width: 143px;
  height: 69px;
  position: absolute;
  top: 296px;
  right:76px;
  animation-delay: 1s;
  animation-fill-mode:forwards;
	// 关键帧名称
	-webkit-animation-name:scaleDraw;
	// 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
	// 动画播放的次数
  -webkit-animation-iteration-count:infinite;
	// 动画所花费的时间
  -webkit-animation-duration:4s;
}

.item-img-big3{
  width: 195px;
  height: 291px;
  position: absolute;
  top: 97px;
  right:49px;
  border-radius: 10px;
  animation-delay: 3s;
  animation-fill-mode:forwards;
	// 关键帧名称
	-webkit-animation-name:scaleDraw1;
	// 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
	// 动画播放的次数
  -webkit-animation-iteration-count:infinite;
	// 动画所花费的时间
  -webkit-animation-duration:5s;
}

.item-img-big4{
  width: 622px;
  height: 354px;
  position: absolute;
  top: 96px;
  right:-160px;
  animation-delay: 1s;
  z-index: -20;
  // animation-fill-mode:forwards;
  // 关键帧名称
  -webkit-animation-name:move;
  // 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
  // 动画播放的次数
  -webkit-animation-iteration-count:infinite;
  // // 动画所花费的时间
  -webkit-animation-duration:10s;
}

.public-title {
  margin-bottom: 0px;
  div{
    margin-top: 20px;
  }
}
.tab {
  height: 120px;
  span{
    margin: 50px 10px 0px 0px;
    box-sizing: border-box;
    float: left;
    list-style: none;
    cursor: pointer;
    text-align: center;
    width: 120px;
    height: 40px;
    background: #e0ebef;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 400;color: #666666;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .active{
    width: 120px;
    height: 40px;
    background: #3288FF;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
}
.con1 {
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    // position: relative;
    img {
      width: 120px;
      height: 120px;
      border-radius: 10px;
    }
    .con_tit{
      font-size: 20px;
      color: #333333;
      line-height: 20px;
      
    }
    .con_text{
      font-size: 14px;
      color: #666666;
      line-height: 22px;
      width: 266px;
    }
  }
}

.con2 {
  .box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    text-align: left;
    margin-right: 85px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-size: 24px !important;
      color: #333;
      line-height: 34px;
      width: 280px;
      font-weight: 500;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 16px;
      color: #666666;
    }
    .text {
      font-size: 16px;
      width: 366px;
      line-height: 26px;
    }
  }
  .iphone-item{
    // width: 293px;
    // height: 519px;
    // position: relative;

    // .item-img-big{
    //   width: 380px;
    //   height: 165px;
    //   padding: 10px;
    //   margin-top: -14px;
    //   opacity: 1;
    //   position: absolute;
    //   z-index: 3;
    //   border: 2px dashed #f55f33;
    //   background-color: rgba(245,95,51,.2);
    //   animation: group1changeSize 4s linear infinite;
    //   animation-fill-mode: forwards
    // }
    // .writecover{
    //   opacity:0;
    //   position: absolute;
    //   z-index: 1;
    //   animation: group1showCover 4s linear infinite;
    //   animation-fill-mode:forwards
    // }
    }
  .right{
    width: 293px;
    height: 519px;
    position: relative;
  }

}

.con3 {
  background-color: #f5f9fd;
  .box {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 70px;
    margin-top:50px;
  }
}

.imgWarp {
  width: 1200px;
  div {
    text-align: center !important;
  }
  img{
    border-radius: 15px;
  }
}
</style>